$rounded: 5px;
$padding: 6px 7px;
$white: #fff;
$gray_100: #f5f5f5;
$gray_200: #eeeeee;
$gray_300: #e0e0e0;
$gray_400: #bdbdbd;
$gray_500: #9e9e9e;
$gray_600: #757575;
$gray_700: #616161;
$gray_800: #424242;
$gray_900: #212121;

$black: #000;

$teal_100: #e6fffa;
$teal_200: #b2f5ea;
$teal_300: #81e6d9;
$teal_400: #4fd1c5;
$teal_500: #38b2ac;
$teal_600: #319795;
$teal_700: #2c7a7b;
$teal_800: #285e61;
$teal_900: #234e52;

@media print {
  .print-hidden {
    display: none;
  }
}

h1 {
  font-size: 3rem!important;
  padding-top: 1rem!important;
  padding-bottom: 1rem!important;
  font-weight: 700!important;
}
h2 {
    font-size: 1.875rem!important;
    color: $teal_500!important;
    // margin-top: -2rem!important;
    margin-bottom: 0.75rem!important;
    font-weight: 700!important;
}
.text-xxs {
  font-size: 0.7rem;
}
td {
  padding: 8px;
}
.input-lg {
  font-size: 1.2em;
}
label,
table th {
  opacity: 0.5;
  font-size: 0.75em;
  font-weight: normal;
}
.table th {
  letter-spacing: 0.06em;
  text-transform: uppercase;
}

.base-component {
  padding: $padding;
  border-radius: $rounded;
}

.badge {
  @extend .base-component;
  font-weight: 600;
  font-size: .8rem;
}
.gm-style-iw ,.gm-style-iw-c {
  background: white;
  color: black;
  font-weight: 600;
}
.theme-dark {
  .marker-orden {
    color: black;
  }
  .marker {
    background-color: white;
    i, .fa { color: black; }
  }
  .floating-window {
    background-color: rgba(0, 0, 0,.8);
  }
  table {
    tr:nth-child(even) {
      background-color: #1f1f1f;
    }
    tr:hover {
      background-color: #242424;
    }
  }
  .scroll {
    overflow-y: scroll;
  }
  .scroll::-webkit-scrollbar,
  ::-webkit-scrollbar {
    width: 8px;
    margin-right: 1px;
    padding: 1px;
  }
  .scroll::-webkit-scrollbar-track,
  ::-webkit-scrollbar-track {
    background-color: $gray_900;
  }
  .scroll::-webkit-scrollbar-thumb,
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: $teal_500;
  }
  .scroll::-webkit-scrollbar-thumb:hover,
  ::-webkit-scrollbar-thumb:hover {
    background-color: $teal_400;
  }
  .scroll::-webkit-scrollbar-thumb:active,
  ::-webkit-scrollbar-thumb:active {
    background-color: $teal_200;
  }

  background-color: $gray_900;
  color: $white;
  aside {
    border-right: 1px solid $gray_900;
  }

  html,
  body,.card  {
    background-color: $gray_900;
    color: $white;
  }
  aside {
    background-color: $gray_900;
    border-right: 1px solid $gray_800 ;
    color: $white;
  }
  aside .active {
    background-color: $black;
  }
  select,
  input,
  textarea,
  .tooltip {
    background-color: $gray_800;
    color: $white;
    @extend .base-component;
    &:hover {
      background-color:$gray_900;
      color: white;
      box-shadow: 0 0 0px 2px rgba($teal_500,.6);
    }
  }
  .ranking-progress {
    background-color: $gray_500;
    color: $white;
  }
  button, .button {
    background-color: $gray_800;
    color: $gray_100;
    padding: 6px 22px;
    @extend .shadow-sm;
    border-radius: $rounded;
    &:hover {
      background-color:$gray_900;
      color: white;
      box-shadow: 0 0 0px 2px rgba($teal_500,.6);
    }
    &.active {
      background-color: $gray_700;
      color: white;
      font-weight: 600;
    }
    &:disabled {
      opacity: 0.5;
    }
  }
  .logo,
  .badge {
    color: $gray_900;
  }
}
.dashboard-card {
  margin-bottom: 2rem;
}
.shadow-sm {
  box-shadow: 0px 1px $rounded rgba(0, 0, 0, 0.05);
}
.shadow-lg {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
}
// LIGHT
.theme-light {
  .marker {
    background-color: black;
    i, .fa { color: white; }
  }
  .marker-orden {
    color: white;
  }
  .floating-window {
    background-color: rgba(255,255,255,.9);
  }
  table {
    tr:nth-child(even) {
      background-color: #fafafa;
    }
    tr:hover {
      background-color: #f7f7f7;
    }
  }
  .scroll {
    overflow-y: scroll;
  }
  .scroll::-webkit-scrollbar {
    width: 8px;
    margin-right: 1px;
    padding: 1px;
  }
  .scroll::-webkit-scrollbar-track {
    background-color: $white;
    box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.1);
  }
  .scroll::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: $teal_500;
  }
  .scroll::-webkit-scrollbar-thumb:hover {
    background-color: $teal_300;
  }
  .scroll::-webkit-scrollbar-thumb:active {
    background-color: $teal_400;
  }

  html,
  body{
    color: $gray_800;

  }
  aside,
  .card {
    background-color: $white;
    color: $gray_800;
  }
  aside .active {
    background-color: $gray_200;
  }
  select,
  input,
  textarea,
  .tooltip {
    background-color: $white;
    border: 1px solid $gray_100;
    color: $gray_700;
    @extend .base-component;
    @extend .shadow-sm;
    &:hover {
      border-color: #fff;
      background-color: white;
      color: $teal_600;
      box-shadow: 0 0 0px 2px rgba($teal_500,.6);

    }
  }
  .ranking-progress {
    background-color: $white;
    border: 1px solid $gray_100;
    color: $gray_700;
    @extend .shadow-sm;
  }
  button, .button {
    background-color: $white;
    border: 1px solid $gray_100;
    color: $gray_700;
    padding: 6px 22px;
    border-radius: $rounded;
    @extend .shadow-sm;
    &:hover {
      border-color: #fff;
      background-color: white;
      color: $teal_600;
      box-shadow: 0 0 0px 2px rgba($teal_500,.6);
    }
    &.active {
      border-color: "#fff";
      color: $black;
      background-color: $white;
      
      font-weight: 600;
      @extend .shadow-lg;
    }
    &:disabled {
      opacity: 0.5;
    }
  }
  .logo,
  .badge {
    color: $white;
  }
  .agenda-details {
    // background-color: $gray_100;
  }
}
summary::-webkit-details-marker {
  display: none;
}

.nodo {
  border-radius: 50%;
   box-shadow: 0px 0px  12px 1px rgba($teal_500,1); 
  animation: radar 15s infinite;
  -webkit-animation: radar 15s infinite;
  -webkit-transition: all ease-in;
  transition: all ease-in;
}

@keyframes radar {
  0% {
    -webkit-transform: scale(0.9, 0.9);
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  95% {
    -webkit-transform: scale(1.05, 1.05);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}
